<template>
    <b-container fluid>
        <b-row>
            <Breadcrumb titulo="TEMPLATES DE CAMPANHAS - WHATSAPP BUSINESS"
                        :items="breadcrumb"/>
        </b-row>
        <b-row class="mx-0 my-3">

            <b-col class="templates-container p-3">
                <div style="display:inline">
                    <b-row>
                        <b-col>
                            <div>
                                <select v-model="setor" class="mr-3  w-25">
                                    <option :value="null">Setor</option>
                                    <option v-for="item in setores" :value="item.Id">{{item.Nome}}</option>
                                </select>
                                <b-button size="sm" class="rounded-0 px-4 py-1 box-cliente" @click="buscarTemplates">Filtrar</b-button>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="data-field insider-icon border-0 w-50">
                                <input type="text" ref="search-input" v-model="search" placeholder="Buscar template" class="w-100" />
                                <i v-if="!search?.trim()" class="fas fa-search fa-fw" @click="$refs['search-input'].focus()"></i>
                                <i v-else class="fas fa-times fa-fw" @click="search = null"></i>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-table v-if="isAvailable" striped :fields="fields" :items="visibleItems">
                    <template #cell(vertical)="data">
                        <span class="text-nowrap">
                            <i :class="`template-status ${data.item.status == 'APPROVED' ? 'online' : (data.item.status == 'PENDING' ? 'pending' : 'offline')}`"></i>
                            {{data.value}}
                        </span>
                    </template>
                    <template #cell(status)="data">
                        <span :class="`template-status-card ${data.value} text-nowrap`">{{templateStatus[data.value]}}</span>
                    </template>
                    <template #cell(templateType)="data">
                        <span class="text-nowrap">
                            <i :class="`fas ${templateTypes[data.value].icon}`"></i>
                            &nbsp;
                            {{templateTypes[data.value].label}}
                        </span>
                    </template>
                    <template #cell(actions)="data">
                        <i v-if="data.item.status == 'APPROVED'" class="fas fa-paper-plane template-send" title="Transmitir para..." @click="enviar(data.item)"></i>
                    </template>
                    <template #cell(data)="data">
                        <span v-html="data.value.replaceAll('\n', '<br />')"></span>
                    </template>
                    <template #cell()="data">
                        {{data.value}}
                    </template>
                </b-table>
                <center v-else>
                    Recurso indisponível no momento — por favor, contate o suporte para maiores informações.
                </center>
            </b-col>
        </b-row>
        <ModalTransmissaoCampanha ref="modal-transmissao" />
        <ModalSelecaoDestinatarios :mandatoryFields="['Telefone']" ref="modal-selecao-destinatarios" />
    </b-container>
</template>
<script>
    import axios from "axios";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import ModalTransmissaoCampanha from "@/components/campanhas/ModalTransmissaoCampanha";
    import ModalSelecaoDestinatarios from "@/components/campanhas/ModalSelecaoDestinatarios";
    export default {
        name: "TemplatesWhatsBusiness",
        components: {
            Breadcrumb,
            ModalTransmissaoCampanha,
            ModalSelecaoDestinatarios
        },
        data() {
            return {
                breadcrumb: [
                    {
                        id: "1",
                        classe: "fas fa-cogs",
                        texto: "Cadastros Básicos",
                        link: "/cadastros-basicos",
                        cor: "#259cd3"
                    },
                    {
                        id: "2",
                        classe: "fa fa-bullhorn",
                        texto: "Campanhas",
                        link: "/campanhas",
                        cor: "#259cd3"
                    },
                    {
                        id: "3",
                        classe: "fab fa-whatsapp",
                        texto: "WhatsApp Business",
                        link: "/campanhas-whatsapp",
                        cor: "#259cd3"
                    }
                ],
                isAvailable: true,
                templateTypes: {
                    TEXT: {
                        icon: "fa-align-left",
                        label: "Texto"
                    },
                    IMAGE: {
                        icon: "fa-image",
                        label: "Imagem"
                    },
                    VIDEO: {
                        icon: "fa-video",
                        label: "Video"
                    },
                    DOCUMENT: {
                        icon: "fa-file-alt",
                        label: "Documento"
                    },
                    LOCATION: {
                        icon: "fa-location-arrow",
                        label: "Localização"
                    }
                },
                templateStatus: {
                    APPROVED: "Aprovado",
                    PENDING: "Em análise",
                    REJECTED: "Rejeitado",
                    DELETED: "Excluído",
                    DISABLED: "Desabilitado"
                },
                fields: [
                    { key: "vertical", label: "Assunto", sortable: true },
                    { key: "templateType", label: "Tipo", sortable: true },
                    { key: "status", label: "Status", sortable: true },
                    { key: "data", label: "Conteúdo" },
                    { key: "actions", label: "" }
                ],
                items: [],
                search: null,
                setor: null,
                setores: null,
            };
        },
        computed: {
            visibleItems() {
                if (!this.items || this.items.length == 0) return [];
                if (this.search?.trim()) return this.items.filter(item => (`${item.vertical} ${item.data}`).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.search.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()))
                return this.items;
            }
        },
        methods: {
            enviar(template) {
                this.$refs["modal-selecao-destinatarios"].select().then(selecao => {
                    this.$refs["modal-transmissao"].open({
                        title: template.vertical,
                        type: template.templateType,
                        content: template.data,
                        additionalInfo: {
                            MetaTemplateId: template.id,
                            TemplateName: template.vertical
                        }
                    }, selecao, 15);
                });
            },
            buscarTemplates() {
                console.log(this.setor)
                $("#caixa_carregamento").fadeIn();
                axios.get("/api/whatsbusiness/templates/" + this.setor).then(response => {
                    console.log("resd", response)
                    if (response.data == '') this.isAvailable = false;

                    this.items = response.data;

                    $("#caixa_carregamento").fadeOut();
                }).catch(e => {
                    if (e.response.status == 404) this.isAvailable = false;
                    $("#caixa_carregamento").fadeOut();
                });
            }

        },
        created() {
            //$("#caixa_carregamento").fadeIn();
            //axios.get("/api/whatsbusiness/templates").then(response => {
            //	this.items = response.data;
            //	$("#caixa_carregamento").fadeOut();
            //}).catch(e => {
            //	if (e.response.status == 404) this.isAvailable = false;
            //	$("#caixa_carregamento").fadeOut();
            //});
            //Buscando Setores
            axios.get("api/setor/lista-basica").then(response => {
                this.$nextTick(() => {

                    console.log(response.data)
                    this.setores = response.data.filter(f => f.WhatsBusinessApp != null);
                });

            })
        }
    }
</script>
<style scoped>
    .breadcrumb {
        background-image: linear-gradient(to bottom, #45ed63, #27ca44);
    }

    .templates-container {
        background-color: var(--cinza-2);
        margin-bottom: 70px;
        min-height: calc(100vh - 150px);
    }

        .templates-container > table {
            background-color: #fff;
            overflow-y: auto;
            margin-bottom: 0;
            font-size: 12px;
            padding: 0 7px 7px 7px;
            margin: 8px 0;
            cursor: default;
        }

    .template-status {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 12px;
    }

        .template-status.online {
            background-color: #27ca44;
        }

        .template-status.offline {
            background-color: var(--cinza-5);
        }

        .template-status.pending {
            background-color: #0094ff;
        }

    .template-status-card {
        background-color: var(--cinza-5);
        color: #fff;
        padding: 3px 5px;
        font-size: 11px;
        border-radius: 3px;
        cursor: default;
    }

        .template-status-card.APPROVED {
            background-color: #27ca44;
        }

        .template-status-card.PENDING {
            background-color: #0094ff;
        }

        .template-status-card.REJECTED {
            background-color: var(--cinza-5);
        }

    .template-send {
        font-size: 13px;
        cursor: pointer;
        transition: color ease-in-out .3s;
    }

        .template-send:hover {
            color: var(--cor-primaria-cliente);
        }
</style>